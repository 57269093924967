import { reject } from "async";
import callApi from "../../util/apiCaller";
import { notify } from "react-notify-toast";
import callEventApi from "../../util/eventApiCaller";
import callIdApi from "../../util/idApiCaller";
import callmessageApi from "../../util/messageApiCaller";
import { processCallToBizDialer } from "./DialerActions";




export const SET_OPERATIONAL_CITY_LIST = 'SET_OPERATIONAL_CITY_LIST'
export const LOCATION = "LOCATION"
export const IS_FETCHING_LOCATION="IS_FETCHING_LOCATION"
export const CONFIG="CONFIG"
export const GET_NOTIFICATION_COUNT = "GET_NOTIFICATION_COUNT"
export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST"
export const FETCH_NOTIFICATION_STATUS = "FETCH_NOTIFICATION_STATUS"
export const GET_VISIT_AGENTS_LIST = "GET_VISIT_AGENTS_LIST"
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOAD_FORM_PARAMS = 'LOAD_FORM_PARAMS';


export function fetchOperationalCityList() {
    return async dispatch => {
        const res = await callApi(`v3/citys/city-list?is_operational=true`, 'GET');
        if (res.status === 'Success') {
            dispatch({
                type: SET_OPERATIONAL_CITY_LIST,
                cityList: res.data.cities
            });
        }
        return;
    };
}
export function fetchConfigs() {
    return async dispatch => {
      try{
        let res =await callEventApi("config")
        if(res.status=="Success"){
        }
        dispatch({
          type:CONFIG,
          configs: res.data
        })
      }
      catch(e){
        console.log(e)
      }
        return;
    };
}


export function notificationCount(count) {
  return {
    type: GET_NOTIFICATION_COUNT,
    notificationcount: count
};
}


export function notificationList(list) {
  return {
    type: GET_NOTIFICATION_LIST,
    notificationlist : list
  }
}

export function visitAgentsList(list) {
  return {
    type: GET_VISIT_AGENTS_LIST,
    allAgents : list
  }
}

export function notificationListStatus(status) {
  return {
    type: FETCH_NOTIFICATION_STATUS,
    notificationlist : status
  }
}
export function loginFailure(message) {
  return {
      type: LOGIN_FAILURE,
      isFetching: false,
      isAuthenticated: false,
      message,
  };
}

export function loginSuccess(user) {
  return {
      type: LOGIN_SUCCESS,
      isFetching: false,
      isAuthenticated: true,
      token: user.token,
      user: user.data,
  };
}
export function loadLeadParams(cities, occasions) {
  return {
      type: LOAD_FORM_PARAMS,
      cities: cities,
      occasions: occasions,
  };
}

function getCurrentPosition() {
    return new Promise( (resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            position => resolve(position),
            error => reject(error)
        )
    })
}

async function checkLocationPermission() {
  try {
    let permission = await navigator.permissions.query({ name: "geolocation" });
    return permission.state
  }
  catch(e){
    console.log(e)
  }
}

const checkIfHasLocation = (longitude, latitude) => {
  if (
    longitude &&
    latitude &&
    !longitude.toString().trim("") == "" &&
    !latitude.toString().trim("") == ""
  ) {
    return true;
  } else return false;
};

export function getLocationData() {
  return async (dispatch) => {
    dispatch({
      type:IS_FETCHING_LOCATION,
      isFetchingLocation:true
    })
    try {
      let checkPermission = await checkLocationPermission();
      if (checkPermission=="granted" || checkPermission=="prompt") {

        try{
          const pos = await getCurrentPosition();
          let longitude = pos?.coords?.longitude;
          let latitude = pos?.coords?.latitude;

          if(checkIfHasLocation(longitude,latitude)){

         dispatch({
          type:IS_FETCHING_LOCATION,
          isFetchingLocation:false
         })
         notify.show("fetched location" , "success" , 1000)
          dispatch({
            type: LOCATION,
            longitude: longitude,
            latitude: latitude,
          });
        }
        else {
          dispatch({
            type:IS_FETCHING_LOCATION,
            isFetchingLocation:false
          })
          throw 'Error in getting location'}

        }
        catch(e){
          dispatch({
            type:IS_FETCHING_LOCATION,
            isFetchingLocation:false
          })
          notify.show("Error in fetching location please close tab and reopen" , "error" , 3000)
          throw e
        }

      } else {
        notify.show("location access disabled Please allow to login", "error", 3000);
        throw 'location access denied'
      }
      return;
    } catch (e) {
      dispatch({
        type:IS_FETCHING_LOCATION,
        isFetchingLocation:false
      })
      console.log(e);
      throw e;
    }



  };
}

export function setLocationData(location){
  return (dispatch)=>{
    dispatch({
      type: LOCATION,
      longitude: location.longitude,
      latitude: location.latitude,
    });

  }

}


export const verifyTotpSuccess = (token) => ({
  type: 'VERIFY_TOTP_SUCCESS',
  payload: { token },
});

export const verifyTotpFailure = (errorMessage) => ({
  type: 'VERIFY_TOTP_FAILURE',
  payload: { errorMessage },
});

export const verifyTotp = (otp, agentId) => async (dispatch) => {
  try {
    const res = await callIdApi(`auth/agent/${agentId}/verify-totp`, 'POST', { code: otp });

    if (res.status === 'Success') {
      dispatch(verifyTotpSuccess(res.data.token));
      notify.show('Success',"success",3000);
      localStorage.setItem('x-token', res.data.token);
    } else {
      throw 'Error'
    }
  } catch (error) {
    dispatch(verifyTotpFailure('Invalid Otp'));
    notify.show('Invalid Otp',error,3000); 
    throw error
  }
};

export function fetchnotificationcount(type,id) {
  return (dispatch) => {
      return  callmessageApi(`v2/notifications/${type}/${id}/unread-notifications`, "get").then((res) => {
          if (res.status === 'Success') {
            dispatch(notificationCount(res.data.count));
          } else {
            notify.show("Failed!", "error");
          }
      });
  };
}


export function fetchnotificationlist(type,userId,pageNum) {
  return (dispatch) => {
      return  callmessageApi(`v2/notifications/${type}/${userId}/list`, "post", {pageNum:pageNum}).then((res) => {
          if (res.status === 'Success') {
            dispatch(notificationList(res.data));
          } else {
            notify.show("Failed!", "error");
          }
      });
  };
}


export function notificationMarkRead(id,type,userid,pageNum) {
  return (dispatch) => {
      return  callmessageApi(`v2/notifications/${id}/mark-read`, "get").then((res) => {
          if (res.status === 'Success') {
            dispatch(fetchnotificationlist(type,userid,pageNum))
            dispatch(fetchnotificationcount(type,userid))
            notify.show("success", "success")
          } else {
            notify.show("Failed!", "error");
          }
      });
  };
}




export function notificationAllMarkRead(type,userid,pageNum) {
  return (dispatch) => {
      return  callmessageApi(`v2/notifications/${type}/${userid}/mark-all-read`, "get").then((res) => {
          if (res.status === 'Success') {
            notify.show("success", "success")
            dispatch(fetchnotificationlist(type,userid,pageNum))
            dispatch(fetchnotificationcount(type,userid))
          } else {
            notify.show("Failed!", "error");
          }
      });
  };
}



export function getVisitAgents(id) {
  return (dispatch) => {
      return  callIdApi(`v3/agents/reporting-managers/${id}/agents`, "get").then((res) => {
          if (res.status === 'Success') {
            dispatch(visitAgentsList(res.data.agents))
          } else {
            notify.show("Failed!", "error");
          }
      });
  };
}

export function consumeMagicToken(mtoken, router) {
  return (dispatch) => {
      return callIdApi(`/auth/partner/consume-magic-token/${mtoken}`, 'post', {}).then((res) => {
          const { user, message } = res;
          if (user) {
              if (!user.ok) {
                  dispatch(loginFailure(message));
                  notify.show("Failed!", "error");
                  // return Promise.reject(message);
              } else {
                  localStorage.setItem('token', user.token);
                  localStorage.setItem('idToken', user.idToken);
                  const event = user.event;
                  router.push(`/events/proposal?etid=${event._id}`);
                  dispatch(loginSuccess(user));
              }
          } else {
              dispatch(loginFailure(message));
              notify.show("Failed!", "error");
        
          }
      });
  };
}